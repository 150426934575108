<template>
  <v-dialog
      v-model="dialog"
      persistent
      :max-width="maxWidth"
      :fullscreen="full_screen"
      :retain-focus="false"
      content-class="non-printable"
  >
    <template v-slot:activator="{ on, attrs }">
      <template v-if="button.iconText">
        <div class="d-flex align-center">
          <v-btn
                  :icon="button.icon"
                  :class="button.buttonClass ? button.buttonClass : button.buttonColor ? null : button.color"
                  :color="button.buttonColor"
                  :width="button.buttonWidth"
                  :small="button.buttonSmall"
                  :plain="button.buttonPlain"
                  :outlined="button.buttonOutlined"
                  :block="button.buttonBlock"
                  :depressed="button.buttonDepressed"
                  :style="button.buttonStyle"
                  v-bind="attrs"
                  v-on="nonClickable ? undefined : on"
                  v-if="!button.iconActivator && !button.buttonHidden"
          >
              <v-icon v-if="button.buttonIcon" :small="button.buttonIconSmall"
                      :class="button.buttonIconClass" :left="button.buttonIconLeft"
                      :color="button.buttonIconColor">{{ button.buttonIcon }}
              </v-icon>
              {{ button.buttonName }}
          </v-btn>
          <v-icon v-if="button.iconActivator"
                  :class="button.class"
                  :color="button.color"
                  v-bind="attrs"
                  :size="button.iconSize"
                  v-on="nonClickable ? undefined : on">
            {{ button.buttonIcon }}
          </v-icon>
          <template v-if="button.iconText">
            <div class="icon-text">
              {{ button.iconText }}
            </div>
          </template>
        </div>
      </template>
      <template v-else>
        <v-btn
                :icon="button.icon"
                :class="button.buttonClass ? button.buttonClass : button.buttonColor ? null : button.color"
                :color="button.buttonColor"
                :width="button.buttonWidth"
                :small="button.buttonSmall"
                :outlined="button.buttonOutlined"
                :block="button.buttonBlock"
                :plain="button.buttonPlain"
                :depressed="button.buttonDepressed"
                :style="button.buttonStyle"
                v-bind="attrs"
                v-on="nonClickable ? undefined : on"
                v-if="!button.iconActivator && !button.buttonHidden"
        >
            <v-icon v-if="button.buttonIcon" :small="button.buttonIconSmall" :class="button.buttonIconClass"
                    :left="button.buttonIconLeft" :size="button.iconSize ? button.iconSize : ''"
                    :color="button.buttonIconColor">{{ button.buttonIcon }}
            </v-icon>
            {{ button.buttonName }}
        </v-btn>
        <v-icon v-if="button.iconActivator"
                :class="button.class"
                :color="button.color"
                v-bind="attrs"
                :size="button.iconSize"
                v-on="nonClickable ? undefined : on">
          {{ button.buttonIcon }}
        </v-icon>
        <template v-if="button.iconText">
          <div class="icon-text">
            {{ button.iconText }}
          </div>
        </template>
      </template>
    </template>
    <component :is="getComponent(main.component)" :title="main.title" :item="item" :min-height="minHeight"
               @closeModal="closeModal"
               :dialog="dialog" @crud="crud"/>
  </v-dialog>
</template>

<script>
import modalComponents from "@/components/modal/index"

function registerComponents() {
  let c = {}
  Object.keys(modalComponents).map(item => {
    const i = modalComponents[item]
    c[i.name] = () => import(`./${i.name}.vue`)
  })
  return c
}


export default {
  name: "MainModal",
  props: {
    main: {
      type: Object,
      default() {
        return {component: '', title: ''}
      }
    },
      tooltip: {
        type: Object,
          default() {
            return {
                active: false,
                title: ''
            }
          }
      },
    button: {
      type: Object,
      default() {
        return {
          icon: false,
          color: 'grey lighten-3',
          buttonIcon: '',
          buttonIconClass: '',
          buttonName: '',
          iconActivator: false,
          class: '',
          iconText: '',
          buttonWidth: null,
          buttonSmall: null,
          buttonOutlined: null,
          buttonBlock: null,
          buttonColor: null,
          buttonClass: null,
          buttonDepressed: null,
          buttonHidden: false,
          buttonStyle: '',
          buttonIconSmall: false,
          buttonIconColor: '',
          buttonPlain: false,
          buttonIconLeft: false
        }
      }
    },
    nonClickable: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: Number,
      default: 800
    },
    minHeight: {
      type: Number,
      number: 0
    },
    item: {
      type: Object,
      default() {
        return {};
      }
    },
    full_screen: {
      type: Boolean,
      default() {
        return false
      }
    },
    modal: {
      type: Boolean,
      default() {
        return false
      }
    },
    afterCalc: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  components: registerComponents(),
  data() {
    return {
      dialog: false,
      watcher: null,
      payload: null,
      emit_good: false
    }
  },
  methods: {
    crud(payload) {
      this.$emit('crud', payload)
    },
    getComponent(name) {
      return modalComponents[name].name
    },
    closeModal(payload = null) {
      this.payload = payload
      this.emit_good = true
      this.$emit('updateItemModal', {id: this.item ? this.item.id : null, modal: false, payload: payload})
      this.dialog = false
    },
    registerWatcher() {
      this.watcher = this.$watch(
          'modal',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.dialog = payload
              }
            }
          }
      )
    }
  },
  created() {
    this.registerWatcher()
  },
  beforeDestroy() {
    if (this.watcher) {
      this.watcher()
    }
  }
}
</script>

<style scoped lang="scss">
.icon-text {
  height: 23px;
  width: 23px;
  border-radius: 50%;
  background: #4eb05226;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: .85rem;
}
</style>